<template>
  <div>
    <div class="container-fluid mt-5">
      <div class="row mb-5">
        <div class="col-md-6 my-auto px-md-5">
          <h3
            data-aos-duration="2000"
            data-aos="fade-right"
            class="mb-4 display-5 header-spacing moving-gradient-text fw-bolder"
          >
            MODSNIP CRM
          </h3>
          <p>
            Adding features and functions to your existing website just got
            super easy!
          </p>
          <p>
            Using our modular snippets you can add contact forms, enquiry forms,
            booking systems, e-commerce shopping experiences and much more to
            your website. No plugins, no expensive upgrades, just a simple embed
            for any page, all your pages, multiple forms on one page... You
            choose!
          </p>
          <p>
            All the data collected by the snippets is intelligently stored on
            your
            <strong>mod<span class="text-pink">snip</span></strong> management
            portal, from there you can manage, interact, communicate, market to
            and engage with your leads, customers or clients.
          </p>
          <p>
            From the
            <strong>mod<span class="text-pink">snip</span></strong> management
            portal, you can customise the look and feel of your snippets, create
            custom forms, provide complex room, person or service based bookings
            or orders and so much more. We are offer a 14 day, no obligation, no
            credit card up-front, trial period. Try
            <strong>mod<span class="text-pink">snip</span></strong> out and
            manage your contacts in an efficient and engaging manner.
          </p>
        </div>
        <div class="col-12 col-md my-auto p-0">
          <img
            src="@/assets/images/pages/modsnip-head.png"
            width="100%"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row text-center">
        <div class="col">
          <h2 class="fw-bolder header-spacing moving-gradient-text mb-5">
            MODULAR FEATURES WITH A MODULAR CRM
          </h2>
          <div class="row">
            <div class="col-md-8 mx-auto">
              <div class="card border-0 bg-light shadow-sm">
                <div class="card-body">
                  <p class="mb-0 fw-bold">
                    Embed features such as contact forms, booking systems,
                    ecommerce, payment gateways and more, driectly on any
                    website. Login to your
                    <strong>mod<span class="text-pink">snip</span></strong>
                    portal and view, manage, report, market to and more!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
